import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "goober";

import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import MaximizeIcon from "@/inline-assets/maximize.svg";
import MinimizeIcon from "@/inline-assets/minimize.svg";
import CloseIcon from "@/inline-assets/windows-close.svg";
import globals from "@/util/global-whitelist.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";

const Wrapper = styled("div")`
  display: flex;
  -webkit-app-region: no-drag;
  z-index: 30;
`;

const Item = styled("button", React.forwardRef)`
  background: transparent;
  padding: 0px;

  > svg {
    width: var(--sp-9) !important;
    height: var(--sp-7);
    background: transparent;
  }

  &:hover {
    background: var(--shade4-25);
    &.close {
      background: var(--primary);
    }

    > svg > path,
    > svg > rect {
      fill: var(--shade0) !important;
      fill-opacity: 1 !important;
    }
  }
`;

const WindowControls = () => {
  const execute = useCallback((type, event) => {
    if (event) {
      // This is so that only either or does a ipc invoke, never both general listener and onClick
      // if this is invoked by onClick and we dont stop it the hit test will trigger a second call.
      event.preventDefault();
      event.stopPropagation();
    }
    blitzMessage(type, null);
  }, []);
  const actions = useMemo(() => {
    return {
      minimize: (ev) => execute(EVENTS.APP_MINIMIZE, ev),
      maximize: (ev) => execute(EVENTS.APP_MAXIMIZE, ev),
      close: (ev) => execute(EVENTS.APP_CLOSE, ev),
    };
  }, [execute]);
  const [refs, setRefs] = useState({});

  const addRef = useCallback(
    (key, ref) => {
      if (refs[key] || !ref) return;
      setRefs({ ...refs, [key]: ref });
    },
    [setRefs, refs],
  );

  useEffect(() => {
    const hitTestListener = (event) => {
      const { x, y } = event;
      for (const key in refs) {
        const rect = refs[key].getBoundingClientRect();
        const match =
          x >= rect.x &&
          x <= rect.x + rect.width &&
          y >= rect.y &&
          y <= rect.y + rect.height;
        if (match) {
          actions[key](null);
          break;
        }
      }
    };
    globals.document.addEventListener("click", hitTestListener);
    return () => globals.document.removeEventListener("click", hitTestListener);
  }, [refs, actions]);

  return (
    <Wrapper className="controls">
      <Item
        ref={(ref) => addRef("minimize", ref)}
        onClick={(ev) => {
          actions["minimize"](ev);
          sendInteractionEvent("app-navigation-windows-minimize-button");
        }}
      >
        <MinimizeIcon />
      </Item>
      <Item
        ref={(ref) => addRef("maximize", ref)}
        onClick={(ev) => {
          actions["maximize"](ev);
          sendInteractionEvent("app-navigation-windows-maximize-button");
        }}
      >
        <MaximizeIcon />
      </Item>
      <Item
        ref={(ref) => addRef("close", ref)}
        className={`close`}
        onClick={(ev) => {
          actions["close"](ev);
          sendInteractionEvent("app-navigation-windows-close-button");
        }}
      >
        <CloseIcon />
      </Item>
    </Wrapper>
  );
};

export default WindowControls;
